import { useState, useEffect, Dispatch, SetStateAction } from "react";
import firebase from "gatsby-plugin-firebase";
import { toast } from "react-toastify";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import JSZip from "jszip";
import saveAs from "file-saver";

import { useStore } from "@state/store";
import { AdCopy, Customer, Fieldset, Order } from "@state/types";
import {
  setDocToState,
  formatBase64,
  calculateTotalOrderPrice,
  isBrowser,
  saveBase64ToStorage,
} from "./helper";
import { usePrevious, useUpdateLogger } from "./hooks";
import { SelectOption } from "./types";
import { appPaths } from "./constants";
import { Maybe } from "@graphql-types";

const firestore = firebase.firestore();
type FirestoreError = firebase.firestore.FirestoreError;

function isFirestoreError(error: unknown): error is FirestoreError {
  return typeof error === "object" && error !== null && "code" in error;
}

const handleFirebaseError = (error: unknown, toastMsg?: string) => {
  toast.error(toastMsg);
  if (isFirestoreError(error)) {
    const errorString = `${error.name} - ${error.code}: ${error.message}`;
    console.error(errorString);
    throw new Error(errorString);
  } else {
    console.error("Unknown error:", error);
    throw new Error("Unknown error");
  }
};

export function useCustomersFromFirestore(customerId?: string): [Customer[] | undefined, boolean] {
  const { user, createCustomerVisible } = useStore();
  if (user == null || !isBrowser()) return [undefined, false];
  const [customers, setCustomers] = useState<Customer[]>();
  const [loading, setLoading] = useState(true);

  const docRef = () => {
    if (user?.admin) return firestore.collection("customers").orderBy("updatedAt", "desc");

    if (customerId) {
      return firestore
        .collection("customers")
        .where(firebase.firestore.FieldPath.documentId(), "==", customerId)
        .orderBy("updatedAt", "desc");
    }

    return firestore
      .collection("customers")
      .where("repCodeRef", "==", user?.repCode)
      .orderBy("updatedAt", "desc");
  };

  useEffect(() => {
    if (!createCustomerVisible) {
      setDocToState(docRef(), setCustomers, setLoading);
    }
  }, [createCustomerVisible]);

  return [customers, loading];
}

export function useOrdersFromFirestore(
  customerId?: string | undefined,
  optionFilter?: SelectOption | undefined,
  maxLimit?: number | undefined,
): [boolean, Order[] | undefined, Order[] | undefined, () => void] {
  const { user, order, setOrder } = useStore();

  const [orders, setOrders] = useState<Order[]>();
  const [filteredOrders, setFilteredOrders] = useState<Order[]>();
  const [loading, setLoading] = useState(true);

  const handleClearFilteredOrders = () => setFilteredOrders(undefined);

  const docRef = () => {
    let query = null;

    if (customerId) {
      query = firestore
        .collection("orders")
        .where("customerId", "==", customerId)
        .orderBy("updatedAt", "desc");

      if (maxLimit != undefined) return query.limit(maxLimit);
      return query;
    }
    if (user?.admin) return (query = firestore.collection("orders").orderBy("updatedAt", "desc"));

    query = firestore
      .collection("orders")
      .where("repCodeRef", "==", user?.repCode)
      .orderBy("updatedAt", "desc");

    if (maxLimit != undefined) return query.limit(maxLimit);
    return query;
  };

  useEffect(() => {
    setLoading(true);

    setDocToState(docRef(), setOrders, setLoading);
  }, [maxLimit]);

  useEffect(() => {
    if (order?.id) {
      const hasOrder = Boolean(orders?.find(o => o.id === order.id));
      if (!hasOrder) setOrder(undefined);
    }
  }, [orders]);

  // filter orders with season selected
  useEffect(() => {
    if (optionFilter == null) return;
    if (orders == null) return;
    const filtered = orders.filter(o => o.season === optionFilter.id);
    if (filtered) setFilteredOrders(filtered);
  }, [optionFilter]);

  return [loading, orders, filteredOrders, handleClearFilteredOrders];
}

export function useSelectCustomerOptions(): [
  SelectOption | undefined,
  Dispatch<SetStateAction<SelectOption | undefined>>,
  (SelectOption | undefined)[] | undefined,
] {
  const [customers] = useCustomersFromFirestore();

  const formatSelectedOption = (customer?: Partial<Customer>) => {
    if (customer == null) return undefined;
    return { id: customer.id, title: `${customer.name} - (${customer.company})` } as SelectOption;
  };

  const { selectedCustomer, setSelectedCustomer } = useStore();

  const stateOption = formatSelectedOption(selectedCustomer);
  const [selectedOption, setSelectedOption] = useState<SelectOption | undefined>(stateOption);
  const [customerOptions, setCustomerOptions] = useState<(SelectOption | undefined)[]>();
  const prevSelectOption = usePrevious(selectedOption);

  useEffect(() => {
    if (customers) {
      const updatedSelect = customers?.map(customer => {
        const option = formatSelectedOption(customer);
        return option;
      });

      setCustomerOptions(updatedSelect);
    }
  }, [customers]);

  useEffect(() => {
    if (selectedCustomer) {
      setSelectedOption(stateOption);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (prevSelectOption?.id === selectedOption?.id) return;
    const customerObject = customers?.find(customer => customer.id === selectedOption?.id);
    if (customerObject) {
      setSelectedCustomer(customerObject);
    }
  }, [selectedOption, customers]);

  return [selectedOption, setSelectedOption, customerOptions];
}

const setOrderToState = (
  docRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  callback: () => void,
  setOrder: (order: Order) => void,
) => {
  docRef.get().then(doc => {
    if (doc.exists) {
      callback();
      const data = doc.data() as Order;
      const id = doc.id;
      setOrder({ ...data, id });
    } else {
      toast.error("Something went wrong with the updating the order.");
    }
  });
};

export function useCreateOrderToFirestore(
  orderState: Partial<Order>,
): [boolean, boolean, () => void] {
  const { selectedCustomer, setOrder, order, user, setAdCopy } = useStore();
  const [orderCreated, setOrderCreated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_, createdOrders] = useOrdersFromFirestore();

  const docRef = firestore.collection("orders").doc();
  const date = new Date();

  useEffect(() => {
    if (selectedCustomer == null) return;
    if (orderCreated && order) {
      const customerRef = firestore.collection("customers").doc(selectedCustomer.id);
      customerRef
        .update({
          orders: firebase.firestore.FieldValue.arrayUnion(order.id),
        })
        .then(() => {
          console.log("order id updated to customer");
        });
    }
  }, [orderCreated, order]);

  const handleDraftOrderClick = () => {
    if (selectedCustomer == null) {
      toast.error("Please select a customer, or create a new customer");
      return;
    }
    const displayOrderID = createdOrders
      ? `${user?.repCode}-${createdOrders?.length + 1}`
      : orderState?.id;

    setLoading(true);
    docRef
      .set(
        {
          ...orderState,
          displayOrderID,
          updatedAt: date,
          customerId: selectedCustomer.id,
          customerName: selectedCustomer.name,
          customerENum: selectedCustomer.eNum,
          customerCompany: selectedCustomer.company,
          country: selectedCustomer.country,
          repCodeRef: user?.repCode,
        },
        { merge: true },
      )
      .then(() => {
        setOrderToState(
          docRef,
          () => {
            setLoading(false);
            setOrderCreated(true);
            setAdCopy(undefined);
          },
          setOrder,
        );
      })
      .catch((error: any) => {
        console.log("update order error", error);
        toast.error("Something went wrong with the order creation.");
      });
  };

  return [orderCreated, loading, handleDraftOrderClick];
}

export function useUpdateOrderToFirestore(
  orderId: string | undefined,
  orderState: Partial<Order>,
  callback: () => void,
): [boolean, (calculateTotal?: boolean) => void] {
  const { selectedCustomer, setOrder } = useStore();
  const [loading, setLoading] = useState(false);

  const docRef = firestore.collection("orders").doc(orderId);
  const date = new Date();

  const handleUpdateOrder = (calculateTotal?: boolean) => {
    if (selectedCustomer == null) {
      toast.error("Please select a customer first, or create a new customer");
      return;
    }

    const orderPrice = calculateTotal ? calculateTotalOrderPrice(orderState) : undefined;
    const setObject = orderPrice
      ? {
          ...orderState,
          totalPrice: orderPrice.totalPrice,
          shippingCost: orderPrice.overallShippingCost,
          updatedAt: date,
        }
      : { ...orderState, updatedAt: date };
    setLoading(true);
    docRef
      .set(setObject, { merge: true })
      .then(() => {
        setOrderToState(
          docRef,
          () => {
            setLoading(false);
            callback();
          },
          setOrder,
        );
      })
      .catch((error: any) => {
        console.log("update order error", error);
        setLoading(false);
        toast.error("Something went wrong with updating the order");
      });
  };

  return [loading, handleUpdateOrder];
}

export function useCreateCustomerToFirestore(
  customerId?: string,
): [boolean, any, Customer, Dispatch<SetStateAction<Customer>>, (event: any) => void] {
  const defaultState = {
    name: "",
    company: "",
    phone: "",
    email: "",
    eNum: "",
    address: "",
    country: process.env.GATSBY_REGION,
    orders: [],
  } as Customer;

  const { user, setCreateCustomerVisible, setSelectedCustomer } = useStore();

  const [formData, setFormData] = useState(defaultState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { pathname } = useLocation();

  const date = new Date();
  const docRef = firestore.collection("customers").doc(customerId);

  useEffect(() => {
    if (!customerId) return;
    setLoading(true);
    docRef
      .get()
      .then(doc => {
        setLoading(false);
        const data = doc.data() as Customer;
        if (data) {
          setFormData(data);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);

    docRef
      .set(
        {
          ...formData,
          updatedAt: date,
          repCodeRef: user?.repCode,
        },
        { merge: true },
      )
      .then(() => {
        docRef.get().then(doc => {
          setLoading(false);
          if (doc.exists) {
            toast.success(customerId ? "Customer changes saved" : "Customer created");
            setCreateCustomerVisible(false);
            const data = doc.data() as Customer;
            const id = doc.id;
            setSelectedCustomer({ ...data, id });

            if (
              [appPaths.createOrder, appPaths.editOrder, appPaths.editCustomer].includes(pathname)
            )
              return;
            navigate(appPaths.customers);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        });
      })
      .catch((error: any) => {
        setLoading(false);
        setError(error);
      });
  };

  return [loading, error, formData, setFormData, handleSubmit];
}

export function useAddTemplateFieldsToCustomer() {
  const { selectedCustomer } = useStore();

  const customerRef = firestore.collection("customers").doc(selectedCustomer?.id);
  console.log({ customerRef });

  const updateFields = (fields: any) => {
    if (fields == null) return;

    customerRef
      .update({
        templateFields: fields,
      })
      .then(() => {
        console.log("template fields updated to customer");
      });
  };

  return { updateFields };
}
export function useDeleteDocumentOnFirebase(
  collectionName: string,
  docId: string | undefined,
  callback: () => void,
): [boolean, () => void] {
  const [loading, setLoading] = useState(false);

  const docRef = firestore.collection(collectionName).doc(docId);

  const handleDelete = () => {
    setLoading(true);
    docRef
      .delete()
      .then(() => {
        setLoading(false);
        toast.success(`Successfully deleted item from ${collectionName.toUpperCase()}`);
        setTimeout(() => {
          callback();
        }, 1000);
      })
      .catch(_error => {
        setLoading(false);
        toast.error("Something went wrong. Please try again later.");
      });
  };

  return [loading, handleDelete];
}

export function useConvertElementsToZipFile(): [
  boolean,
  boolean,
  (pdfBlob?: Blob, saveLocally?: boolean, adCopyToSave?: AdCopy) => Promise<void>,
] {
  const { adCopy, order, getOrderZipName } = useStore();
  const [loading, setLoading] = useState(false);
  const [hasSent, setSent] = useState(order?.hasSent ?? false);
  const zipName = getOrderZipName();

  const convertToZip = async (pdfBlob?: Blob, saveLocally?: boolean, adCopyToSave?: AdCopy) => {
    const ad = adCopyToSave ?? adCopy;
    if (!ad) return;
    const { adCopyElements, copy, notes } = ad;
    const zip = new JSZip();
    const imagesFolder = zip.folder("images");
    if (imagesFolder == null || copy == null) return;

    //convert ad copy to base64
    const copyBuffer = await formatBase64(copy);
    if (copyBuffer) imagesFolder.file("ad-copy.png", copyBuffer, { binary: true });
    if (!adCopyElements) return;

    const { imageElements, textElements, shapeElements, backgroundColour, backgroundUrl } = adCopyElements;

    if (backgroundUrl) {
      const backgroundBuffer = await formatBase64(backgroundUrl);
      if (backgroundBuffer) imagesFolder.file("background.png", backgroundBuffer, { binary: true });
    }
    if (notes) zip.file("notes.txt", notes);
    if (backgroundColour) zip.file("background-colour.txt", JSON.stringify(backgroundColour));
    if (shapeElements) zip.file("shapes.txt", JSON.stringify(shapeElements));
    if (textElements) zip.file("text.txt", JSON.stringify(textElements));
    if (imageElements) {
      await Promise.all(
        imageElements.map(async (imageElement, index) => {
          const { value, id } = imageElement;

          const valueBuffer = await formatBase64(value);
          if (valueBuffer)
            imagesFolder.file(`${id ?? index + 1}.png`, valueBuffer, { binary: true });
        }),
      );

      const noImageDataimageElements = imageElements.map(imageElement => {
        const { value, ...rest } = imageElement;
        return rest;
      });
      zip.file("images.txt", JSON.stringify(noImageDataimageElements));
    }

    if (zipName) {
      if (pdfBlob) zip.file(`${zipName}.pdf`, pdfBlob);
      const storageRef = firebase.storage().ref(`${zipName}.zip`);
      setLoading(true);
      // zip files then save to firebase storage
      try {
        const blob = await zip.generateAsync({ type: "blob" });

        if (blob) {
          if (saveLocally) {
            saveAs(blob, zipName);
            setLoading(false);
            toast.success("Zip file downloaded. Please check your downloads folder.");
            return;
          }

          storageRef.put(blob).then(_snapshot => {
            setLoading(false);
            toast.success("Order files backup saved");
            setSent(true);
          });
        } else {
          toast.error(`Error backing up files`);
        }
      } catch (error) {
        toast.error(`Error backing up files, ${error}`);
      }
    }
  };

  return [loading, hasSent, convertToZip];
}

/**
 * Get download url from Firebase storage (old url before order id change)
 *
 * @param hasSent boolean to check if order has been sent
 * @returns download url for file saved
 */
export function useDownloadUrlFromStorage(hasSent: boolean | undefined) {
  const { getOrderZipName, order } = useStore();
  const zipName = getOrderZipName();

  const [oldDownloadUrl, setOldDownloadUrl] = useState<string>();
  const [downloadUrl, setDownloadUrl] = useState<string>();

  const getDownloadUrlFromRef = async (
    refName: string | undefined,
    setUrl: Dispatch<SetStateAction<string | undefined>>,
  ) => {
    const storageRef = firebase.storage().ref(`${refName}.zip`);
    if (storageRef == null) return;
    try {
      const url = await storageRef.getDownloadURL();
      if (url) setUrl(url);
    } catch (error) {
      console.log({ error });
      setUrl(undefined);
    }
  };

  useEffect(() => {
    getDownloadUrlFromRef(order?.id, setOldDownloadUrl);
    getDownloadUrlFromRef(zipName, setDownloadUrl);
  }, []);

  useEffect(() => {
    if (hasSent) {
      getDownloadUrlFromRef(zipName, setDownloadUrl);
    }
  }, [hasSent]);

  return downloadUrl ?? oldDownloadUrl;
}

export function useSelectCustomer(customerId: string | undefined) {
  const customerRef = firestore.collection("customers").doc(customerId);
  const [loading, setLoading] = useState(false);
  const { setSelectedCustomer, selectedCustomer } = useStore();

  useEffect(() => {
    if (customerId === selectedCustomer?.id) return;
    setLoading(true);
    customerRef
      .get()
      .then(doc => {
        if (!doc.exists) return;
        const data = doc.data() as Customer;
        const id = doc.id;
        setSelectedCustomer({ ...data, id });
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  return loading;
}
/**
 * Bulk delete test data from a certain date (for development purposes)
 */
export function useDeleteStuff(dateString?: string, collectionName?: string) {
  const [toDelete, setToDelete] = useState<any[]>();
  const [_loading, setLoading] = useState(false);
  const collection = collectionName ?? "orders";
  const date = new Date(dateString ?? "2022-07-26");

  const docRef = firestore
    .collection(collection)
    .where("updatedAt", "<", date)
    .orderBy("updatedAt", "desc");

  useUpdateLogger(toDelete);

  useEffect(() => {
    setDocToState(docRef, setToDelete, setLoading);
  }, []);

  useEffect(() => {
    if (toDelete?.length) {
      const deletePromises = toDelete
        .map(item => {
          const docRef = firestore.collection(collection).doc(item.id);
          return docRef.delete();
        })
        .map(promise =>
          promise
            .then(() => {
              console.log("deleted");
            })
            .catch(error => {
              console.log(error);
            }),
        );
      Promise.all(deletePromises);
    }
  }, [toDelete]);
}

/*
 * Template hooks
 */
export function useSaveTemplate() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const saveTemplate = async (data: any, calendarID: string) => {
    setLoading(true);
    const templateRef = firestore.collection("templates").doc(calendarID);

    try {
      await templateRef.set(data);
      toast.success("Template saved");
    } catch (error) {
      handleFirebaseError(error, "Error saving template");
    }
    setLoading(false);
  };

  const saveFieldset = async (data: Fieldset, fieldsetID: string) => {
    setLoading(true);

    const fieldsetRef = firestore.collection("fieldsets").doc(fieldsetID);
    console.log({ fieldsetRef });

    try {
      await fieldsetRef.set(data);
      toast.success("Fieldset saved");
    } catch (error) {
      handleFirebaseError(error, "Error saving fieldset");
    }

    setLoading(false);
  };

  const getTemplate = async (calendarID: string) => {
    const templateRef = firestore.collection("templates").doc(calendarID);
    const template = await templateRef.get();
    return template.data();
  };

  const getFieldset = async (fieldsetID: string) => {
    // const templateRef = firestore.collection("fieldsets").where("gridcode", "==", fieldsetID).get();
    // const template = await templateRef.get();
    const snapshot = await firestore.collection("fieldsets").get();
    const allFields = snapshot.docs.map(doc => doc.data());
    const filteredTemplates = allFields.filter(
      template => template?.gridcode?.toUpperCase() === fieldsetID?.toUpperCase(),
    );
    return filteredTemplates;

    // const snapshot = await firestore
    //   .collection("fieldsets")
    //   .where("gridcode", "==", fieldsetID)
    //   .get();

    // return snapshot.docs.map(doc => doc.data());
  };

  const getAllTemplates = async (adCopyCode?: string) => {
    if (adCopyCode) {
      const snapshot = await firestore.collection("templates").orderBy("id", "desc").get();

      const allTemplates = snapshot.docs.map(doc => doc.data());
      const filteredTemplates = allTemplates.filter(
        template => template.adcopyCode.toUpperCase() === adCopyCode.toUpperCase(),
      );

      return filteredTemplates;
    }
    const snapshot = await firestore.collection("templates").orderBy("id", "desc").get();
    return snapshot.docs.map(doc => doc.data());
  };

  const getAllFieldsets = async () => {
    const snapshot = await firestore.collection("fieldsets").get();
    return snapshot.docs.map(doc => doc.data());
  };

  return {
    saveTemplate,
    loading,
    error,
    getTemplate,
    getAllTemplates,
    getAllFieldsets,
    saveFieldset,
    getFieldset,
  };
}

export function useSaveAdCopy() {
  const setAdCopy = useStore(state => state.setAdCopy);

  const getAdCopy = async (calendarID: string) => {
    const adcopyRef = firestore.collection("adCopies").doc(calendarID);
    const template = await adcopyRef.get();
    return template.data();
  };

  const saveAdCopy = async (data: AdCopy, calendarID: Maybe<string> | undefined) => {
    if (!calendarID || !data) return;
    const templateRef = firestore.collection("adCopies").doc(calendarID);
    const folderPath = `image-assets/${calendarID}/`;
    const { adCopyElements, copy } = data;

    try {
      toast.info("Saving images to cloud - this may take some time, please wait...⏳");
      // Process image elements asynchronously and wait for all promises to resolve
      const imageElements = await Promise.all(
        adCopyElements?.imageElements?.map(async imageElement => {
          const { value, id } = imageElement;
          const storagePath = `${folderPath}${id}.png`;
          const imageValue = await saveBase64ToStorage(value, storagePath);
          toast.info(`Image ${id} saved to the cloud, please continue to wait...⏳`);
          return { ...imageElement, value: imageValue || value };
        }) || [],
      );

      toast.info("Saving ad copy to the cloud, almost there....⌛️");
      const copyUrl = await saveBase64ToStorage(copy, `${folderPath}ad-copy.png`);
      const backgroundUrl = await saveBase64ToStorage(adCopyElements?.backgroundUrl, `${folderPath}background.png`);

      // Prepare the data to save
      const dataToSave = {
        ...data,
        copy: copyUrl,
        adCopyElements: {
          ...adCopyElements,
          ...(backgroundUrl && { backgroundUrl: backgroundUrl }),
          imageElements,
        },
      } as AdCopy;

      // Save the data to Firestore
      await templateRef.set(dataToSave);

      setAdCopy(dataToSave);
      toast.success("Ad Copy saved to the cloud 🙌");
    } catch (error) {
      handleFirebaseError(error, "Error saving ad copy, file size of image may be too large");
    }
  };

  return { getAdCopy, saveAdCopy };
}
