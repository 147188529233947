import styled, { createGlobalStyle } from "styled-components";

import { colors, fontFamilies, MOBILE_BREAKPOINT } from "@util/constants";
import { fontSizes, fontWeights } from "@util/constants";
import { Color } from "@util/types";

export const GlobalStyle = createGlobalStyle<{ defaultColor?: Color }>`
  
  body {
    font-family: ${fontFamilies.gotham};
    margin: 0px;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${props => (props.defaultColor ? colors[props.defaultColor] : colors.navy)};
    white-space: pre-wrap;
    overflow-x: hidden;
  }

  h1, .heading-h1 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.bold};
    white-space: pre-wrap;

  }

  h2, .heading-h2 {
    font-size: ${fontSizes.h2.default}px;
    font-weight: ${fontWeights.bold};
    white-space: pre-wrap;
  }
 
  h3, .heading-h3 {
    font-size: ${fontSizes.h3.default}px;
    font-weight: ${fontWeights.bold};
    white-space: pre-wrap;
  }

  h4, .heading-h4 {
    font-size: ${fontSizes.h4.default}px;
    font-weight: ${fontWeights.medium};
    white-space: pre-wrap;
  }

  .heading-big {
    font-size: ${fontSizes.big.default}px !important;
    font-weight: ${fontWeights.bold};
    white-space: pre-wrap;
  }
  
  .heading-big, .heading-h1, .heading-h2, .heading-h3, .heading-h4 {
    margin: 0 !important;
  }

  a {
    user-select: none;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: ${fontWeights.medium};
    color:${colors.navy};

    &:hover {
      filter: brightness(0.9);
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-kerning: normal;
  font-kerning: normal;
  input,
  textarea,
  button,
  select,
  a, img, div {
      -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
  body {
    font-size: ${fontSizes.p.mobile}px;
  }

  h1, .heading-h1 {
    font-size: ${fontSizes.h1.mobile}px;
  }

  h2, .heading-h2 {
    font-size: ${fontSizes.h2.mobile}px;
  }
 
  h3, .heading-h3 {
    font-size: ${fontSizes.h3.mobile}px;
  }

  h4, .heading-h4 {
    font-size: ${fontSizes.h4.mobile}px;
  }

  .heading-big {
    font-size: ${fontSizes.big.mobile}px !important;
  }

  .slices-wrapper {
    padding: 0 10px;
  }

  }

`;

export const Main = styled.main`
  margin: 107px auto 0 auto;
`;
